'use client';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { signIn } from 'next-auth/react';
export default function LoginForm() {
  return <Card className='w-full max-w-sm' data-sentry-element="Card" data-sentry-component="LoginForm" data-sentry-source-file="page.tsx">
            <CardHeader className='text-center' data-sentry-element="CardHeader" data-sentry-source-file="page.tsx">
                <CardTitle className='text-2xl' data-sentry-element="CardTitle" data-sentry-source-file="page.tsx">Welcome</CardTitle>
                <CardDescription data-sentry-element="CardDescription" data-sentry-source-file="page.tsx">Sign in to your account using Office365</CardDescription>
            </CardHeader>
            <CardContent data-sentry-element="CardContent" data-sentry-source-file="page.tsx">
                <Button onClick={() => signIn('cognito')} className='w-full' variant='outline' data-sentry-element="Button" data-sentry-source-file="page.tsx">
                    Sign in with Office365
                </Button>
            </CardContent>
        </Card>;
}